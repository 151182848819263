// require('dotenv').config()
// export const baseUrl =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:8080/"
//     // : "https://believeit-dev.herokuapp.com/";
//     : "http://184.73.250.202/";

export const baseUrl =
    process.env.NODE_ENV === "development"
        ? "https://dev.believeit.app/"
        : "https://admin.believeit.app/";

// export const baseUrl = "https://dev.believeit.app/"
// export const baseUrl = "http://localhost:8080/"
export const apiPath = baseUrl + "api";
export const basePath = "/admin";